<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.medium"
    scrollable
    tile
  >
    <close-btn 
      @click="closeDialog()" 
      overflow
    />

    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col cols="3" class="text-center">
            <template v-if="user">
              <UserPhoto
                photoSize="medium"
                :id="user.userid"
                :size="150"
                editable
                readonly
                rounded
              />
            </template>
          </v-col>
          <v-col cols="9">
            <preloader v-if="!user" />
            <v-form ref="accountForm" v-if="user">
              <v-text-field
                v-model="user.fullName"
                label="Full Name"
                outlined
                readonly
              ></v-text-field>

              <v-text-field
                v-model="user.email"
                label="Email"
                persistent-hint
                outlined
                readonly
              ></v-text-field>

              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    label="Account Created"
                    :value="user.createdAt.toDate()"
                    append-icon="mdi-lock"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <!-- <v-col class="py-0">
                  <v-text-field
                    label="Last Login"
                    :value="currentUser.metadata.lastSignInTime"
                    append-icon="mdi-lock"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      user: null,
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false
    },

    showDialog(user) {
      this.user = user
      this.dialog = true
    }
  }
}

</script>